// react
import React from 'react';
import {Progress, ListItem, AppLogo} from "../components";
import {SysStr, UserInfo} from "../types";

/**
 * SplashScreen
 */

interface ViewProps {
  user: UserInfo,
  onNavigate: (action: string) => void
  onUpdate: (field: string, value: string) => Promise<boolean>
}
interface ViewState {
}

const GenderList = (props: ViewProps) => {
  // setup
  const {options} = SysStr.gender;
  const {user} = props;
  const vList = options.map(item => {
    const type = (user.gender === item.id) ? ' highlight' : '';
    return <ListItem style={type} title={item.value} action='nav-settings' onClick={() => onUpdate(item.id)}/>
  })

  const onUpdate = async (gender: string)=> {
    if (await props.onUpdate('gender', gender)) {
      props.onNavigate('complete');
    }
  }

  // render
  return <div className='body'>
    <table className='gender-table'>
      {vList}
    </table>
  </div>
}

export class GenderScreen extends React.Component<ViewProps, ViewState> {

  render()
  {
    // setup
    const {user, onNavigate, onUpdate} = this.props;
    const {description} = SysStr.gender;

    // render
    return <div className='page'>

      <div className='header'>
        <text className='title'>{description}</text>
      </div>

      <GenderList user={user} onNavigate={onNavigate} onUpdate={onUpdate}/>

      <div className='footer'>
        <Progress step={3}/>
        <AppLogo/>
      </div>

    </div>
  }

}
