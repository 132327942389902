// react
import React from 'react';

/**
 * SplashScreen
 */

interface ViewProps {
    step: number
}

export const Progress = (props: ViewProps) => {
    // setup
    const {step} = props;
    const vProgress = [1, 2, 3].map(item => {
        const style = item === step ? 'progress active' : 'progress';
        return <div className={style}></div>
    })

    // render
    return <div className='progress-wrapper'>
        {vProgress}
      </div>
}
