// react
import React from 'react';

// local
import {SysStr, UserInfo} from "../types";
import {Progress, ListItem, AppLogo} from "../components";

/**
 * SplashScreen
 */

interface ViewProps {
  user: UserInfo,
  onNavigate: (action: string) => void
  onUpdate: (field: string, value: string) => Promise<boolean>
}
interface ViewState {
}

const AgeList = (props: ViewProps) => {
  // setup
  const {options} = SysStr.age;
  const {user} = props;
  const vList = options.map(item => {
    const type = (user.age === item.id) ? ' highlight' : '';
    return <ListItem style={type} title={item.value} action='nav-gender' onClick={() => onUpdate(item.id)}/>
  });

  const onUpdate = async (age: string)=> {
    if (await props.onUpdate('age', age)) {
      props.onNavigate('complete');
    }
  }

  // render
  return <div className='body'>
    <table className='age-table'>
      {vList}
    </table>
  </div>
}

export class AgeScreen extends React.Component<ViewProps, ViewState> {

  render()
  {
    // setup
    const {user, onNavigate, onUpdate} = this.props;
    const {description} = SysStr.age;

    // render
    return <div className='page'>

      <div className='header'>
        <text className='title'>{description}</text>
      </div>

      <AgeList user={user} onNavigate={onNavigate} onUpdate={onUpdate}/>

      <div className='footer'>
        <Progress step={2}/>
        <AppLogo/>
      </div>
    </div>
  }

}
