export const SysStr = {
	// Global
	title: 'FREELOCALTV',

	button: {
		agree: 'Agree',
		cancel: 'Cancel',
		delete: 'Delete',
		exit: 'Exit',
		help: '?',
		ok: 'OK',
		start: 'Get Started',
	},
	keyboard: [
		{id: '1', value: '1'},
		{id: '2', value: '2'},
		{id: '3', value: '3'},
		{id: '4', value: '4'},
		{id: '5', value: '5'},
		{id: '6', value: '6'},
		{id: '7', value: '7'},
		{id: '8', value: '8'},
		{id: '9', value: '9'},
		{id: 'delete', value: 'Delete'},
		{id: '0', value: '0'},
		{id: 'enter', value: 'Enter'},
	],

	// Page
	age: {
		title: 'Age Range',
		description: 'Select your age range',
		options: [
			{id: '1', value: '6-11'},
			{id: '2', value: '12-17'},
			{id: '3', value: '18-24'},
			{id: '4', value: '25-34'},
			{id: '5', value: '35-44'},
			{id: '6', value: '45-54'},
			{id: '7', value: '55-64'},
			{id: '8', value: '65+'}
		]
	},
	avatar: {
		title: "Select your avatar"
	},
	gender: {
		title: 'Gender',
		description: 'What best describes you?',
		options: [
			{id: 'm', value: 'Man'},
			{id: 'f', value: 'Woman'},
		]
	},
	help: {
		title: 'FreeLocalTV Help',
		description: "<dl>"
			+ "<dt>Q. What is FreeLocalTV?</dt>"
			+ "<dd>A. FreeLocalTV (FLTV) is a turnkey solution that leverages modern infrastructure to enable secondary transmissions of over-the-air broadcast television signals through the Internet to freely available apps. The equipment and technology that makes up FLTV is available through Free Local TV Foundation to state governments so they can provide greater ease-of-access to local TV channels to members of the public within the borders of their authority. We realize that was a mouthful but that's basically it.</</dt>"
			+ "<dt>Q. Who owns FLTV?</dt>"
			+ "<dd>A. The FLTV streaming service for each local TV market is owned and operated by the applicable state government.</dd>"
			+ "<dt>Q. Is FLTV really free?</dt>"
			+ "<dd>A. Yes, without charge to the recipients.</dd>"
			+ "<dt>Q. Is there a hidden assessment or charge or fee?</dt>"
			+ "<dd>A. No. We use anonymous data to cover our costs. Let us be clear. We don't know who you are but we know the device type, zip code, age range, and gender provided during registration along with the corresponding viewing history (e.g., channels, programs). None of this data is personally identifiable but it is helpful to broadcasters and advertisers which is a good thing. No one wants local TV to disappear like local newspapers. So, the service is free to you, the broadcast and advertising industries can license precise viewership data (no guessing or fancy algorithms), and there is no fee from the state to defray the actual and reasonable costs of maintaining and operating the secondary transmission service. WIN-WIN-WIN!</dd>"
			+ "<dt>Q. How do I get FLTV?</dt>"
			+ "<dd>A. To get started, find or download the FLTV app on your device. Apps are found in the app store for the corresponding device and are free to download and install.</dd>"
			+ "<dt>Q. What platforms does FLTV support?</dt>"
			+ "<dd>A. FLTV supports connected TV devices like Apple TV, Fire TV, and Roku; smart TVs like Samsung, LG, and Sony; portable devices smart phones and tablets running iOS or Android; computers running Windows or MacOS; and gaming consoles like Xbox.</dd>"
			+ "<dt>Q. What local TV channels does FLTV include?</dt>"
			+ "<dd>A. The goal is to include the most popular local TV channels in each market but this is decided by the corresponding state.</dd>"
			+ "<dt>Q. What does the FLTV streaming service offer viewers?</dt>"
			+ "<dd>A. FLTV provides a traditional TV experience. The first time you start the app, you create an anonymous profile to confirm your local TV market. Each time thereafter, the app will live stream the last channel you viewed. At any time, you can change the channel up or down. Any interaction (e.g., button press, key press, mouse movement, screen touch) displays the system menu on the bottom of the screen. The system menu shows the current channel and program information. It also allows you to select a channel from the program guide, select an alternate audio track (if any), toggle closed captions on/off, and view/edit your anonymous profile information. If you do nothing, the system menu fades away.</dd>"
			+ "<dt>Q. How do I interact with the FLTV app?</dt>"
			+ "<dd>A. Each FLTV app supports the following operations and actions: UP, DOWN, LEFT, RIGHT, SCROLL UP, SCROLL DOWN, SCROLL LEFT, SCROLL RIGHT, SELECT, PLAY/PAUSE, and BACK. UP performs a channel up operation during playback or moves the highlight up. DOWN performs a channel down operation during playback or moves the highlight down. LEFT moves the highlight left. RIGHT moves the highlight right. SCROLL UP/DOWN/LEFT/RIGHT scrolls the highlight in the corresponding direction. SELECT chooses the current highlighted option or button. PLAY/PAUSE toggles channel playback (pauses and resumes live), and BACK moves one level back (or exits the app).</dd>"
			+ "<dt>Q. How do I perform these operations and actions on devices with a remote control?</dt>"
			+ "<dd>A. The UP, DOWN, LEFT, and RIGHT actions are performed using the arrow buttons. SCROLL UP/DOWN/LEFT/RIGHT are also performed using the arrow buttons. SELECT uses the OK or select button, PLAY/PAUSE is performed during playback using either the OK or select button or the play/pause button, and BACK uses the back or back-arrow button. Easy peasy.</dd>"
			+ "<dt>Q. How do I perform these operations and actions on devices with a touch-screen?</dt>"
			+ "<dd>A. Channel up/down is performed by swiping up or down during playback. Navigation is performed by touching the desired option or button on the screen. Scrolling is performed by swiping the desired direction. SELECT is performed by touching the highlighted option or button. BACK is a built-in operation on Android but not on iOS. To exit the app on iOS, swipe up from the very bottom of the screen. The FLTV app uses only landscape or widescreen mode on these devices.</dd>"
			+ "<dt>Q. How do I perform these operations and actions on devices with a keyboard and mouse?</dt>"
			+ "<dd>A. KEYBOARD: The UP, DOWN, LEFT, and RIGHT actions are performed using either the arrow keys or the no hand shifting option of the w, s, a, and d keys. Scrolling is performed using the arrow keys and the page up/down keys. SELECT uses the OK or select key. BACK uses the backspace key. Finally, PLAY/PAUSE is performed using is the play/pause key (if any) or the spacebar during playback.</dd>"
			+ "<dd>A. MOUSE: Navigation is performed by moving the mouse over the desired option or button. Scrolling is performed using the mouse wheel or wheels (when available). Select is performed using a left-mouse click on the highlighted option or button. BACK is performed using a right-mouse click. Finally, PLAY/PAUSE is performed with a left mouse click on the video playback window.</dd>"
			+ "<dt>Q. Can I pause and resume playback?</dt>"
			+ "<dd>A. Yes and no. FLTV streams live TV channels. If you pause, playback automatically jumps to \"live\" when you resume.</dd>"
			+ "<dt>Q. Can I download and watch local TV content offline?</dt>"
			+ "<dd>A. No. FLTV streams local TV channels as they are received over-the-air. There is no record capability.</dd>"
			+ "<dt>Q. How does the program guide work?</dt>"
			+ "<dd>A. FLTV collects guide data found in the over-the-air TV signals and updates this information every 30 minutes. Please note that guide data is limited to what broadcasters include in their over-the-air signals. At the very least, you will see channel information, program name, and start/end times. You may also see a program description, series/episode information, and a content rating.</dd>"
			+ "<dt>Q. What about content ratings?</dt>"
			+ "<dd>A. Ratings provide guidance to viewers on the suitability of a program for certain audiences based on its content. The Federal Communications Commission (FCC) enforces strict guidelines for over-the-air TV. Specifically, the FCC prohibits explicit language, nudity, and graphic violence, between 6am and 10pm when children are likely to be watching. Broadcasters typically use the following ratings: TV-Y, TV-Y7, TV-G, TV-PG, and TV-14. TV-MA rated programs are not permitted on over-the-air TV. Due to these built-in safeguards, FLTV does not provide parental controls.</dd>"
			+ "<dt>Q. What about secondary audio?</dt>"
			+ "<dd>A. If there is more than one audio track, you can select it from the system menu at the bottom of the screen. Please note that sometimes broadcasters mislabel audio tracks. It may say 'Spanish' but it's actually descriptive \"English\" for the seeing impaired. We have no control over this.</dd>"
			+ "<dt>Q. What about closed captions?</dt>"
			+ "<dd>A. You can toggle closed captions on and off from the system menu at the bottom of the screen. Broadcasters are required to include closed captions but we have no control over this.</dd>"
			+ "<dt>Q. Will I see advertisements on FLTV?</dt>"
			+ "<dd>A. Yes. Traditional broadcast TV, including major networks, air commercials during programming. These ads are typically 30-second spots that are inserted into the broadcast stream and sent over-the-air.</dd>"
			+ "<dt>Q. Can I skip advertisements?</dt>"
			+ "<dd>A. No. FLTV streams live TV channels. Advertisements are baked into the channel streams and there is no way to skip to the future. Over-the-air TV is free but ad-supported. That’s the deal.</dd>"
			+ "<dt>Q. Can I see the tutorial screen you showed the first time I used FLTV?</dt>"
			+ "<dd>A. Yes. The profile screen selected from the system menu at the bottom of the screen displays a question mark in the lower right corner. This will display the tutorial screen and provide access to these questions and answers in the app.</dd>"
			+ "<dt>Q. Can I watch my local TV channels when I'm outside the coverage area?</dt>"
			+ "<dd>A. No. The service is geofenced to the applicable state and local TV market geographic boundaries. That said, if the FLTV streaming service is available where the device is physically located, you are free to watch those local TV channels.</dd>"
			+ "<dt>Q. What happens if I cross a state or local TV market boundary while watching FLTV?</dt>"
			+ "<dd>A. As soon as the system recognizes this, playback will stop.</dd>"
			+ "<dt>Q. Can I use a VPN?</dt>"
			+ "<dd>A. No. A VPN (or virtual private network) masks the general location of your device. In order to use FLTV, you must disable any VPN.</dd>"
			+ "<dt>Q. Why does the system sometimes ask for device location permission?</dt>"
			+ "<dd>A. If we are having trouble determining the general location of your device or if your device appears to be located near a state or local TV market boundary, we may ask for device location permission (depending on the device). If you choose not to provide the requested permission (only used while watching), you may be denied access.</dd>"
			+ "<dt>Q. Can I setup more than one profile?</dt>"
			+ "<dd>A. No. This is planned for a future release. When this happens, you will be able to assign an avatar image to each profile (since we don't collect personally identifiable data). If more than one profile is created, the system will ask, \"Who's Watching?\" (i.e., which avatar) each time the FLTV app is loaded. The benefit to viewers is that each will see the last channel they viewed. Also, the more accurate the viewing data, the more tailored advertisers can be to those who are actually watching and there's nothing wrong with that; especially since the only one that knows who you are is you... yea!).</dd>"
			+ "<dt>Q. Are there any other noteworthy limitations?</dt>"
			+ "<dd>A. Due to hardware and Internet bandwidth constraints, we may not be able to offer all of the local channels in your market or provide simultaneous access to all users across all devices; especially during abnormally high-peak times. System access is provided and maintained on a first-come, first-served basis. That said, the system watches this carefully and reports when additional equipment or bandwidth is advisable.</dd>"
			+ "<dt>Q. Do you have any playback tips?</dt>"
			+ "<dd>A. Yes. If you're playing online games, downloading or uploading large files while trying to watch a show on the same network, this can cause the video player to use a lower quality stream. If you experience this, reduce the number of devices in use on the same network. If you're unsure, test your Internet speed. We recommend your Internet connection speeds (wired, Wi-Fi, or 4G/5G/LTE) to be at least 3Mbps for high-def quality and 8Mbps for super high-def (when available over-the-air).</dd>"
			+ "<dt>Q. What do I do if I'm having a technical difficulty?</dt>"
			+ "<dd>A. This is a difficult one (no pun intended). Since we don't collect any personally identifiable data (and an email address or phone number IS personally identifiable), we have no way of communicating back and forth with you. We do our best to keep this document updated and to report easily understandable error conditions. For example, if your device can't reach the Internet. If the service is temporarily unavailable. If a channel is temporarily unavailable. If you entered an invalid zip code. If the zip code you entered is not located within a state that offers FLTV. If the zip code you entered does not match the general location of your device. If it appears that your device is located outside the coverage area. If it appears that your device is using a VPN (or virtual private network). IF YOU RECEIVE AN ERROR MESSAGE THAT IS NOT ACCURATE OR YOU ARE HAVING TROUBLE AND CAN'T FIND AN ANSWER HERE, you can go to http://fltvlabs.org/help and send us feedback. We log all error conditions and look at all feedback. That said, please remember that this service is offered for free. There are no call centers or teams of support engineers.</dd>"
			+ "</dl>"
	},
	registered: {
		description: "Streaming local channels from Salt Lake City, Utah.",
		url: "Visit FreeLocalTV.utah.org for details.",
	},
	tos: {
		title: 'Terms of Service',
		description: "<ol>"
			+ "<li>FREELOCALTV: This is a public service owned and operated by the State of Utah and is provided to you at no charge.</li>"
			+ "<li>AGREEMENT: By using this service, you agree to these terms and conditions. If you do not agree, don’t use the service.</li>"
			+ "<li>AUDIENCE: FreeLocalTV is made available to residents and visitors of the State of Utah and is intended for individuals aged 18 or older or those with parental consent.</li>"
			+ "<li>PRIVACY: We do NOT collect any data that is personally identifiable. You agree that the anonymous data we do collect (e.g., zip code, age range, gender) can be licensed to others to cover our costs. You also agree that the anonymous data you provide to us is accurate.</li>"
			+ "<li>PERSONALIZATION: You agree that advertisements may appear during playback and these ads may or may not target your specific demographic.</li>"
			+ "<li>CONTENT: FreeLocalTV is like a virtual retransmission tower. We stream live TV programs as they are broadcast—like what you would see with antenna TV if the over-the-air signal was available and unobstructed.</li>"
			+ "<li>LOCATION: The device that runs the FreeLocalTV app must be located inside the State of Utah (or other participating state) including the applicable local TV boundary.</li>"
			+ "<li>LIMITATIONS: You understand that due to hardware and Internet bandwidth constraints, we may not be able to offer all of the local channels in your market or provide simultaneous access to all users across all devices; especially during abnormally high-peak times. System access is provided and maintained on a first-come, first-served basis.</li>"
			+ "<li>DISCLAIMER: THIS SERVICE IS PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE BASIS, WITHOUT WARRANTIES OR GUARANTEES OF ANY KIND, INCLUDING NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND AS TO QUALITY, AVAILABILITY, AND SUBJECT MATTER OF CONTENT. In no event, shall the State of Utah be liable for any damages resulting from the use of or inability to use the service.</li>"
			+ "<li>LIABILITY: YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT FREELOCALTV’s ACTS OR OMISSIONS, THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING OPERATION OF THE SERVICES.</li>"
			+ "<li>LAW & FORUM: These terms shall be governed by and construed in accordance with the laws of the State of Utah with exclusive jurisdiction in the state and federal courts in Salt Lake City, Utah.</li>"
			+ "<li>QUESTIONS: For questions regarding these terms, contact us at FreeLocalTV.utah.gov.</li>"
			+ "</ol>"
	},
	unregistered: {
		description: "<dd>A public service owned and operated by participating state governments.",
		disclaimer: "We use anonymous demographic data so we can offer the service for free. We collect no personally-identifiable data.",
		url: "Visit FreeLocalTV.org for more information.",
	},
	zipcode: {
		title: 'Zip Code',
		description: 'Enter your zip code',
		invalid: 'The Zip Code you entered does not match the general location of your device. Please verify your Zip Code and try again. Visit FreeLocalTV.org for more information.'
	},
}

export const System = (function () {

	function clearStorage(keyName: string) {
		localStorage.removeItem(keyName);
		sessionStorage.removeItem(keyName);
	}

	function getStorage(keyName: string): any | null {
		let dstData = localStorage.getItem(keyName);
		if (!dstData) {
			dstData = sessionStorage.getItem(keyName);
		}
		return dstData ? JSON.parse(dstData) : null;
	}

	function setStorage(storage: string, keyName: string, srcData: any) {
		let dstData: any;
		if (storage === 'local') {
			const data = localStorage.getItem(keyName);
			if (typeof srcData === 'object') {
				dstData = data ? JSON.parse(data) : {};
				for (let i in srcData) {
					dstData[i] = srcData[i]; // overwrite key-value pairs.
				}
			} else {
				dstData = data ? JSON.parse(data) : [];
				dstData.push(srcData); // append array data.
			}
			localStorage.setItem(keyName, JSON.stringify(dstData));
		} else {
			const data = sessionStorage.getItem(keyName);
			if (typeof srcData === 'object') {
				dstData = data ? JSON.parse(data) : {};
				for (let i in srcData) {
					dstData[i] = srcData[i];
				}
			} else {
				dstData = data ? JSON.parse(data) : [];
				dstData.push(srcData); // append array data.
			}
			sessionStorage.setItem(keyName, JSON.stringify(dstData));
		}
	}

	/*
	 * Public Access
     */
	return {
		clearStorage: clearStorage,
		getStorage: getStorage,
		setStorage: setStorage,
	}

})();
